*{
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
 
}
h1,h2,h3,h4{
  overflow-y: hidden;
}
Link{
  text-decoration: none;
}
@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins"),
   url("./font/Poppins-Bold.ttf") format("truetype");
}
  
@font-face {
  font-family: "Poppins-BoldItalic";
  src: local("Poppins"),
   url("./font/Poppins-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins"),
   url("./font/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-MediumItalic";
  src: local("Poppins"),
   url("./font/Poppins-MediumItalic.ttf") format("truetype");
}


@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins"),
   url("./font/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins"),
   url("./font/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBoldItalic";
  src: local("Poppins"),
   url("./font/Poppins-SemiBoldItalic.ttf") format("truetype");
}

body{
  background-color: #F1F1F1;
  overflow-y: hidden;

}
a{
  text-decoration: none;
}

img{
  pointer-events: none;
  cursor: default;
}


.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  
}



